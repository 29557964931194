import { generateId } from './id';
/**
 * Logger Façade
 */

const logger = {
	internalLogger: null,
	loggerLoaded: false,

	setInternalLogger(logger: unknown): unknown {
		this.internalLogger = logger;
		return this.internalLogger;
	},

	/**
	 * Logs information
	 *
	 * @param message The message to log
	 */
	info: (message: string, ...args: unknown[]): void => {
		if (logger.loggerLoaded) {
			logger.internalLogger.info(message, ...args);
			return;
		}

		const style = 'background-color: #cce5ff; color: #004085; border-radius: 0.25rem; padding: .2rem 0.4rem; border: 1px solid #b8daff;';
		console.info('%cInfo', style, message, ...args);
	},

	/**
	 * Logs a warning
	 *
	 * @param message The message to log
	 */
	warn: (message: string, ...args: unknown[]): void => {
		if (logger.loggerLoaded) {
			logger.internalLogger.warn(message, ...args);
			return;
		}

		console.warn(message, ...args);
	},

	/**
	 * Logs a debug message
	 *
	 * @param message The message to log
	 */
	debug: (message: string, ...args: unknown[]): void => {
		if (logger.loggerLoaded) {
			logger.internalLogger.debug(message, ...args);
			return;
		}

		console.debug(message, ...args);
	},

	/**
	 * Logs an error
	 *
	 * @param message The error message
	 */
	error: (message: string, ...args: unknown[]): void => {
		if (logger.loggerLoaded) {
			logger.internalLogger.error(message, ...args);
			return;
		}

		console.error(message, ...args);
	},

	/**
	 * Starts a timer
	 */
	startTimer: (): { done: (message: unknown) => void } => {
		if (logger.loggerLoaded) {
			const profiler = logger.internalLogger.startTimer();
			return profiler;
		}

		const id = generateId(6);
		console.time(id);
		return {
			done: (message: unknown) => {
				message['timer'] = id;
				console.info(message);
				console.timeEnd(id);
			},
		};
	},
};

/**
 * Winston Logger is a huge library with huge dependencies
 * that we don't want in our first load javascript
 * so we opt to lazy load it through our logger façade.
 */
if (!logger.loggerLoaded) {
	import('./_loggerWinston').then((module) => {
		logger.setInternalLogger(module.default);
		logger.loggerLoaded = true;
	});
}

export default logger;
