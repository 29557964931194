import React, { useContext } from 'react';

export const ApplicationContext = React.createContext<Partial<Site.Application>>(null);

export interface ApplicationContextProviderProps {
	application: Site.Application;
	children?: React.ReactNode;
}

export const ApplicationContextProvider: React.FC<ApplicationContextProviderProps> = ({ application, children }) => {
	return <ApplicationContext.Provider value={application}>{children}</ApplicationContext.Provider>;
};

export function useApplicationContext(): Partial<Site.Application> {
	return useContext(ApplicationContext);
}
