import { getCmsNavigation } from 'application/adapters/cms/navigationAdapter';
import logger from 'helpers/logger';

export interface GetNavigationOptions {
	byName?: string;
	currentUrl?: string;
	includeHidden?: boolean;
	includeRootItem?: boolean;
}
export interface NavigationRepositoryInterface {
	getNavigation: (options?: GetNavigationOptions) => Navigation.NavigationItem[];
	getBreadcrumb: (options?: GetNavigationOptions) => Navigation.NavigationItem[];
}

export const NavigationRepositoryFactory = (data: Navigation.NavigationItem): NavigationRepositoryInterface => {
	if (!data) return;

	const createNavigationItem = (navigation: Navigation.NavigationItem, options?: GetNavigationOptions): Navigation.NavigationItem => {
		if (navigation.hidden && !options?.includeHidden) {
			return null;
		}

		const descendants = navigation.descendants
			?.map((item) => {
				return createNavigationItem(item, options);
			})
			?.filter((item) => item);

		const isCurrent = options?.currentUrl ? options?.currentUrl === navigation.url : false;
		const isActive = isCurrent || descendants?.find((item) => item.current || item.active) ? true : false;
		return {
			id: navigation.id,
			name: navigation.name,
			url: navigation.url,
			descendants: descendants?.length === 0 ? null : descendants,
			title: navigation.title ?? null,
			hidden: navigation.hidden ?? null,
			current: isCurrent,
			active: isActive,
		};
	};

	const getNavigationItems = (options?: GetNavigationOptions): Navigation.NavigationItem[] => {
		const root = options?.byName ? findByName(options?.byName, data) : data;
		if (!root) return null;

		const itemsToReturn = options.includeRootItem ? [root] : root.descendants;

		if (!itemsToReturn) {
			logger.error('Reference error - NavigationRepository: Navigation items array is undefined');
			return null;
			// throw new Exception('NavigationRepository: Navigation items array is undefined');
		}

		return itemsToReturn.map((item) => createNavigationItem(item, options))?.filter((item) => item);
	};

	return {
		// Return visible navigation items.
		// This is only done for the first level for now
		// and could be refined if the need arises.
		getNavigation(options): Navigation.NavigationItem[] {
			return getNavigationItems(options);
		},

		// Returns the bread crumb
		// For now just the root and the current item (if any)
		getBreadcrumb(options): Navigation.NavigationItem[] {
			const flattenItems = (items: Navigation.NavigationItem[]) => {
				const flattened = [];
				items.forEach((element) => {
					flattened.push(element);
					if (element.descendants) {
						const flattenedDesc = flattenItems(element.descendants);
						flattenedDesc.forEach((f) => flattened.push(f));
					}
				});
				return flattened;
			};
			const items = getNavigationItems(options);
			return flattenItems(items).filter((item) => item.active);
		},
	};
};

export interface GetNavigationInterface {
	preview?: boolean;
	previewData?: Content.PreviewData;
}
// Return full set of nested navigation items.
// This is pure data for static props and must be serializable
// Preferably it should have been part of the repository factory function
// but objects cannot be passed as static props
export const getNavigationData = async ({ preview, previewData }: GetNavigationInterface): Promise<Navigation.NavigationItem> => {
	const navigationResponse = await getCmsNavigation({
		preview,
		previewData,
	});
	return navigationResponse;
};

// Return navigation items by its name.
// This is only done for the first level of descendants for now
// and could be refined if the need arises.
const findByName = (name: string, item: Navigation.NavigationItem): Navigation.NavigationItem => {
	const normalizedName = name.toLowerCase();
	if (item.name === normalizedName) {
		return item;
	} else {
		const foundItem = item.descendants.filter((item) => {
			return item.name.toLowerCase() === normalizedName;
		});
		if (foundItem.length <= 0) {
			logger.error('Reference error - NavigationRepository: FindByName returned not found name:', name);
		}
		return foundItem.length
			? foundItem[0]
			: {
					id: 0,
					name: '',
					url: '',
			  };
	}
};
