// Using Object Literal as Singleton Pattern
const Application: Site.Application = {
	url: null,
	locale: null,
	envVars: null,

	setUrl(url): URL {
		this.url = new URL(url);
		return this.url;
	},

	setLocale(tag, options): Intl.Locale {
		this.locale = new Intl.Locale(tag, options);
		return this.locale;
	},

	setEnvVar(key, value): { [key: string]: string } {
		this.envVars = this.envVars ?? {};
		this.envVars[key] = value;
		return this.envVars[key];
	},
};

export default Application;
