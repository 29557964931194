import logger from 'helpers/logger';
import { queryCmsContent } from './_api';

export const getDictionaryFromCms = async (preview: boolean, previewData: Content.PreviewData): Promise<Content.DictionaryItem[]> => {
	const query = `{
		dictionary {
			key
			values {
				culture
				value
			}
		}
	}`;

	const profiler = logger.startTimer();
	try {
		const dictionary = await queryCmsContent({
			query,
			preview,
			previewData,
			method: 'POST',
		});
		return dictionary?.dictionary;
	} finally {
		profiler.done({ message: 'getDictionary', level: 'debug' });
	}
};
