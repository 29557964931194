import classNames from 'classnames';
import styles from './Link.module.scss';
import { useRouteContext } from 'ui/contexts/RouteContext';
import { Icon } from '../../Media/Icon/Icon';
import RightArrow from 'ui/icons/icon-right-button-arrow.svg';
import RightArrowKp from 'ui/icons/icon-right-button-arrow-kp.svg';
import { forwardRef } from 'react';

export interface LinkProps {
	children?: React.ReactNode;
	style?: 'primary' | 'sm' | 'md' | 'lg' | 'xl';
	inheritsColor?: boolean;
	color?: 'violet';
	ariaLabel?: string;
	className?: string;
	href?: string;
	target?: string;
	title?: string;
	rel?: string;
	clickHandler?: (event: React.MouseEvent) => void;
	locale?: string;
	iconLink?: boolean;
	noUnderline?: boolean;
	underline?: boolean;
	globalTheme?: string;
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
	(
		{
			children,
			style,
			inheritsColor,
			ariaLabel,
			className,
			href,
			target,
			title,
			rel,
			clickHandler,
			locale,
			color,
			iconLink,
			noUnderline,
			underline,
			globalTheme,
		},
		ref,
	) => {
		const routeHandler = useRouteContext();
		return (
			<>
				{!href && !clickHandler ? (
					<>{children}</>
				) : (
					<a
						ref={ref}
						href={href}
						target={target}
						title={title}
						rel={rel}
						aria-label={ariaLabel}
						className={classNames(
							styles.Link,
							styles[`Link___${style}`],
							styles[`Link___${color}`],
							{ [styles.Link___icon]: iconLink },
							{ [styles.Link___noUnderline]: noUnderline },
							{ [styles.Link___underline]: underline },
							{ [styles.Link___inheritsColor]: inheritsColor },
							className,
						)}
						onClick={(event) => {
							if (clickHandler) {
								clickHandler(event);

								return;
							}

							// We only run the routeHandler if no clickHandler is provided.
							routeHandler && routeHandler(event, href, locale, target);
						}}
					>
						{children}
						{iconLink && (
							<Icon size="md" className={classNames(styles.Link_iconContainer)}>
								{globalTheme === 'kogp' ? <RightArrowKp /> : <RightArrow />}
							</Icon>
						)}
					</a>
				)}
			</>
		);
	},
);
