import React, { useContext } from 'react';
import { DEFAULT_LOCALE } from '../adapters/cms/_constants';

export class Locale {
	private _locale: string;
	private _language: string;
	private _country: string;
	private _dateFormatter: Intl.DateTimeFormat;

	public constructor(locale: string) {
		this.setLocale(locale);
	}

	setLocale(locale: string): void {
		this._locale = locale;
		[this._language, this._country] = this._locale.split('-');
		this._dateFormatter = new Intl.DateTimeFormat(locale, { year: 'numeric', month: '2-digit', day: '2-digit' });
	}

	formatDate(date: Date): string {
		if (!date) return '';
		const d = this._dateFormatter.format(date).toString();

		return d;
	}

	formatStringDate(dateString: string): string {
		if (!dateString) return '';
		const date = new Date(dateString);
		const d = this._dateFormatter.format(date);

		return d;
	}
}

export const LocaleContext = React.createContext<Partial<Locale>>(new Locale(DEFAULT_LOCALE ?? 'da-DK'));

export function useLocaleContext(): Partial<Locale> {
	return useContext(LocaleContext);
}
