export interface RequestHeadersInterface {
	hostname: string;
	preview?: boolean;
	previewData?: Content.PreviewData;
	locale?: string;
	accessToken?: string;
}

export const requestHeaders = ({ hostname, preview, previewData, locale, accessToken }: RequestHeadersInterface): { [key: string]: string } => {
	const headers = {};
	headers['Content-Type'] = 'application/json';
	headers['Hostname'] = hostname;

	if (preview && typeof previewData === 'object') {
		headers['Authorization'] = previewData?.data?.auth;
	} else if (accessToken) {
		headers['Authorization'] = `Bearer ${accessToken}`;
	}
	if (locale) {
		headers['Content-Culture'] = locale;
	}

	return headers;
};
