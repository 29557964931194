import logger from 'helpers/logger';
import { queryCmsContent } from './_api';

export interface NavigationAdapterRequestInterface {
	preview: boolean;
	previewData: Content.PreviewData;
}

export interface NavigationAdapterResponseInterface {
	properties: Record<string, unknown>;
	children: Array<NavigationAdapterResponseInterface>;
	id: number;
	culture: string;
	name: string;
	url: string;
	documentType: string;
	template: string;
	createDate: string;
	sortOrder: number;
}

// Function to transform JSON to a more friendly structure
// Basically this just replaces children with descendants
// but could completely transform the JSON if desired
const transform = (item): Array<Navigation.NavigationItem> => {
	return item.map((child) => {
		return {
			id: child.id,
			name: child.name,
			url: child.url,
			hidden: child.hidden,
			title: child.title,
			descendants: transform(child.children),
		};
	});
};

export const getCmsNavigation = async ({ preview, previewData }: NavigationAdapterRequestInterface): Promise<Navigation.NavigationItem> => {
	const path = '/';
	const method = 'POST';
	const query = `{
		navigation: navigation(path: "${path}" maxDepth: 5 includeHidden : true)
	}`;

	const profiler = logger.startTimer();

	try {
		const content = await queryCmsContent({ method, query, preview, previewData });
		const transformedData: Navigation.NavigationItem = {
			...transform([content?.navigation])[0],
		};
		return transformedData;
	} finally {
		profiler.done({ message: 'getNavigation', level: 'debug' });
	}
};
