import type { AppProps as NextAppProps } from 'next/app';
import { SiteContextProvider } from 'application/contexts/SiteContext';
import { DictionaryContextProvider } from 'application/contexts/DictionaryContext';
import { PageContextProvider } from 'application/contexts/PageContext';
import { NavigationContextProvider } from 'application/contexts/NavigationContext';
import 'ui/styles/globals.scss';
import { StrictMode, useEffect } from 'react';
import Application from 'application/entities/Application';
import { ApplicationContextProvider } from 'application/contexts/ApplicationContext';
import { Locale, LocaleContext } from 'application/contexts/LocaleContext';
import { ThemeProvider } from 'next-themes';
import { GLOBAL_THEME_DEFAULT } from '_constants';

// modified version - allows for custom pageProps type, falling back to 'any'
// https://stackoverflow.com/questions/64722812/what-typescript-type-should-nextjs-app-tsx-component-and-pageprops-be
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AppProps<P = any> = {
	pageProps: P;
} & Omit<NextAppProps<P>, 'pageProps'>;

const App: React.FC<NextAppProps> = ({ Component, pageProps }: AppProps) => {
	const { content, dictionary, navigation, site, application } = pageProps ?? {};

	const { page } = content ?? {};
	const { culture } = page ?? {};
	const localeProvider = new Locale(culture ?? 'da-DK');

	const globalTheme: string = site.settings.globalTheme?.setTheme ?? GLOBAL_THEME_DEFAULT;
	/**
	 * This useEffect transfers application values from pageProps to
	 * a client-side exposed instance of the Application entity that can be
	 * accessed in adapters, repositories and other non React client-side modules
	 *
	 * If you want to access the values inside a React module you can use the
	 * ApplicationContext
	 */
	useEffect(() => {
		application?.url ? Application.setUrl(application.url) : null;
		application?.locale ? Application.setLocale(application.locale) : null;
		application?.envVars ? (Application.envVars = application.envVars) : null;
	}, [application]);

	return (
		<StrictMode>
			<ApplicationContextProvider application={application}>
				<SiteContextProvider site={site}>
					<DictionaryContextProvider dictionary={dictionary ?? []} culture={culture}>
						<LocaleContext.Provider value={localeProvider}>
							<PageContextProvider content={content}>
								<NavigationContextProvider navigation={navigation}>
									<ThemeProvider forcedTheme={globalTheme}>
										<Component {...pageProps} />
									</ThemeProvider>
								</NavigationContextProvider>
							</PageContextProvider>
						</LocaleContext.Provider>
					</DictionaryContextProvider>
				</SiteContextProvider>
			</ApplicationContextProvider>
		</StrictMode>
	);
};

export default App;
