import React, { useContext } from 'react';
import { Preview } from '../../features/Preview/Preview';

export class PageContextProps {
	private _pageContent: Content.PageContent;

	public constructor(pageContent: Content.PageContent) {
		this._pageContent = pageContent;
	}

	public get template(): string {
		return this._pageContent?.page?.template;
	}

	public get name(): string {
		return this._pageContent?.page?.name;
	}

	public get settings(): Content.Page['properties']['pageSettings'] {
		return this._pageContent?.page?.properties?.pageSettings;
	}

	public get id(): number {
		return this._pageContent?.page?.id;
	}

	public get path(): string {
		return this._pageContent?.page?.url;
	}

	public get content(): Content.Page['properties'] {
		return this._pageContent?.page?.properties;
	}

	public get footer(): Content.PageContent['footer'] {
		return this._pageContent?.footer;
	}

	public get preview(): boolean {
		return this._pageContent?.preview ?? false;
	}

	public get previewData(): Content.PreviewData {
		return this._pageContent?.previewData;
	}

	public get culture(): string {
		return this._pageContent?.page?.culture ?? 'da-DK';
	}

	public getSettings(key: 'siteTheme'): Content.SiteTheme['content']['properties'];
	public getSettings(key: 'navigation'): Content.Navigation['content']['properties'];
	public getSettings(key: 'openGraph'): Content.OpenGraph['content']['properties'];
	public getSettings(
		key: 'siteTheme' | 'navigation' | 'openGraph',
	): (Content.SiteTheme | Content.Navigation | Content.OpenGraph)['content']['properties'] {
		const setting = this.settings?.find((setting) => setting.documentType === key);
		return setting?.content?.properties;
	}
}

export const PageContext = React.createContext<Partial<PageContextProps>>(new PageContextProps(null));

export interface PageContextProviderProps {
	content: Content.PageContent;
	children?: React.ReactNode;
}
export const PageContextProvider: React.FC<PageContextProviderProps> = ({ content, children }) => {
	const pageContext: PageContextProps = new PageContextProps(content);

	return (
		<PageContext.Provider value={pageContext}>
			<Preview url={content?.page?.url}>{children}</Preview>
		</PageContext.Provider>
	);
};

export function usePageContext(): Partial<PageContextProps> {
	return useContext(PageContext);
}
