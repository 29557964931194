import { ApiError } from 'helpers/apiError';
import { ApiRedirect } from 'helpers/apiRedirect';
import { axiosInstance as axios } from 'helpers/axiosInstance';
import { CONTENT_API_URL } from './_constants';
import { requestHeaders } from './_requestHeaders';
import Application from 'application/entities/Application';
import logger from 'helpers/logger';

export interface RequestInterface {
	preview?: boolean;
	previewData?: Content.PreviewData;
	path?: string;
	query?: string;
	method: 'GET' | 'POST';
}

// TODO: Using type any, as the exact right type is not found in now. Refactors in the adapters, will most likely be required, to make the right type.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const queryCmsContent = <TResponse = any>({ preview, previewData, method, query, path }: RequestInterface): Promise<TResponse> => {
	if (!CONTENT_API_URL) {
		logger.error('[500] Internal Server Error \nContent api url is not defined');
		throw new ApiError('Internal Server Error', 500, 'Content api is not defined');
	}

	if (path === 'umbraco') {
		logger.error('[404] PageNotFound \nPage not found');
		throw new ApiError('PageNotFound', 404, 'Page not found');
	}

	const origin = Application.url?.origin;
	const locale = Application.locale?.baseName;

	const headers = requestHeaders({ hostname: origin, preview, previewData, locale });

	const contenturl = encodeURI(`${CONTENT_API_URL}${path ? path : ''}${preview ? '?preview=1' : ''}`);

	return axios({
		url: contenturl,
		method: method,
		maxRedirects: 0,
		headers,
		data: {
			query,
		},
	})
		.then((res) => {
			let result;

			if (method === 'POST') {
				const { data, errors } = res.data;

				if (errors) {
					throw new Error(errors[0].message);
				}
				if (!data) {
					throw new Error(`The Content Api returned no content from '${contenturl}'. Is the content system configured and running?`);
				}
				result = data;

				result.preview = preview ? true : false;
				result.previewData = previewData ? previewData : null;
			}

			if (method === 'GET') {
				const { data } = res;
				result = data;
			}
			return result;
		})
		.catch((error) => {
			const message = error?.response?.data?.message ?? error;
			const statusCode = error?.response?.status || 500;

			if (statusCode === 301 || statusCode === 302 || statusCode === 307 || statusCode === 308) {
				const statusName = statusCode
					? statusCode === 301
						? 'Moved Permanently'
						: statusCode === 302
						? 'Found'
						: statusCode === 307
						? 'Temporary Redirect'
						: statusCode === 308
						? 'Permanent Redirect'
						: ''
					: 'statusCode not definded';
				logger.info(`[${statusCode}] Redirection: ${statusName} \n${error?.response?.headers?.location}`);
				throw new ApiRedirect(statusCode, error?.response?.headers?.location);
			}

			if (statusCode === 404) {
				logger.error(`[${statusCode}] Client error \nPage not found`);
				throw new ApiError('PageNotFound', 404, 'Page not found');
			}

			if (statusCode === 401 || statusCode === 403) {
				// "401 Unauthorized" really means "unauthenticated"
				// "403 Forbidden" really means "unauthorized"
				logger.error(`[${statusCode}] Client error \n${error?.response?.data?.data}`);
				return error?.response?.data?.data;
			}

			logger.error(`[${statusCode}] Undetected API error in queryCmsContent() \n${message}`);
			throw new ApiError('Undetected API error', statusCode, message);
		});
};
